import { Loader, Panel } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import useStationPayAvailability from '../../../lib/hooks/useStationPayAvailability';
import { getStationDisplayName } from '../../../lib/utils/station';
import StationPropType from '../../prop-types/station';
import BadgeAds from '../BadgeAds';
import EnterpriseBadge from '../EnterpriseBadge';
import FavoriteStationIcon from '../FavoriteStationIcon';
import PhoneLink from '../PhoneLink';
import StarRating from '../StarRating';
import StationBrandings from '../StationBrandings';
import StationDisplayName from './StationDisplayName';
import StationHours from './StationHours';
import styles from './StationInfoBox.module.css';

const cx = classnames.bind(styles);

export default function StationInfoBox({
  bordered,
  hasPwGBCard,
  hasPwGBPlusCard,
  payAccountStatus,
  showAdditional,
  singleLineAddress,
  station,
}) {
  const {
    id: stationId,
    address,
    badges,
    brands = [],
    enterprise: isEnterprise,
    hours,
    name: stationName,
    phone,
    ratingsCount: numRatings,
    starRating: rating,
    isFuelmanSite,
  } = station || {};

  const isPayAvailable = useStationPayAvailability(station);

  if (!station) {
    return <Loader style={{ width: '100%' }} data-testid="loader" />;
  }

  return (
    <Panel
      className={cx('stationInfoContainer')}
      color="white"
      bordered={bordered}
      padded={false}
      rounded
    >
      <div className={cx('logoColumn')}>
        {stationName && (
          <StationBrandings
            brands={brands}
            className={cx('brands')}
            stationName={getStationDisplayName(brands, stationName)}
          />
        )}
      </div>
      <div className={cx('mainInfo')}>
        {(stationName || !!brands.length) && (
          <StationDisplayName
            brands={brands}
            stationName={stationName}
            as="h2"
            headerClass={cx('header')}
            subheaderClass={cx('stationNameSubHeader')}
            headerBadge={showAdditional && isEnterprise && (
              <EnterpriseBadge />
            )}
          />
        )}
        {showAdditional && (
          <Fragment>
            <StarRating
              name={`station${stationId}Rating`}
              size="large"
              rating={rating}
            />
            &nbsp;
            <span className={cx('ratings')}>
              {`(${numRatings || 0})`}
            </span>
          </Fragment>
        )}
        <div className={cx('regular')}>
          <div className={cx('ellipsisNoWrap')}>
            {!!address && (
              <span>
                <span>{address.line1 ? address.line1 : null}</span>
                <span>{address.line2 ? address.line2 : null}</span>
                <span>
                  {singleLineAddress ? ' - ' : <br />}
                  {address.locality ? address.locality : null}
                  ,&nbsp;
                  {address.region ? address.region : null}
                  &nbsp;
                </span>
              </span>
            )}
          </div>
        </div>
        {showAdditional && (
          <Fragment>
            <BadgeAds
              badges={badges}
              hasPwGBCard={hasPwGBCard}
              hasPwGBPlusCard={hasPwGBPlusCard}
              isPayAvailable={isPayAvailable}
              isFuelmanLocation={isFuelmanSite}
              payAccountStatus={payAccountStatus}
            />
            {phone && <PhoneLink number={phone} />}
            {hours?.status && (
              <div style={{ position: 'relative' }}>
                <StationHours hours={hours} />
              </div>
            )}
          </Fragment>
        )}
      </div>
      {showAdditional && (
        <div className={cx('favorite')}>
          <FavoriteStationIcon stationId={stationId} verbose />
        </div>
      )}
    </Panel>
  );
}

StationInfoBox.propTypes = {
  // Whether to show in an outlined container
  bordered: PropTypes.bool,
  hasPwGBCard: PropTypes.bool,
  hasPwGBPlusCard: PropTypes.bool,
  payAccountStatus: PropTypes.string,
  // Whether to show more than basic station info
  showAdditional: PropTypes.bool,
  // Whether to show the station address on a single line or not
  singleLineAddress: PropTypes.bool,
  // Station details
  station: StationPropType,
};

StationInfoBox.defaultProps = {
  bordered: false,
  hasPwGBCard: false,
  hasPwGBPlusCard: false,
  payAccountStatus: undefined,
  showAdditional: false,
  singleLineAddress: false,
  station: undefined,
};
