import gql from 'graphql-tag';

export const GET_STATION = gql`
  query GetStation($id: ID!, $reviewsPage: Int) {
    station(id: $id) {
      address {
        country
        line1
        line2
        locality
        postalCode
        region
      }
      amenities {
        amenityId
        imageUrl
        name
      }
      badges {
        badgeId
        callToAction
        campaignId
        clickTrackingUrl
        description
        detailsImageUrl
        detailsImpressionTrackingUrls
        imageUrl
        impressionTrackingUrls
        internalName
        targetUrl
        title
      }
      brands {
        brandId
        brandingType
        imageUrl
        name
      }
      currency
      emergencyStatus {
        hasGas {
          nickname
          reportStatus
          stamp
          updateDate
        }
        hasPower {
          nickname
          reportStatus
          stamp
          updateDate
        }
        hasDiesel {
          nickname
          reportStatus
          stamp
          updateDate
        }
      }
      enterprise
      fuels
      hasActiveOutage
      hours {
        nextIntervals {
          close
          open
        }
        openingHours
        status
      }
      id
      latitude
      longitude
      name
      nearby {
        address {
          country
          line1
          locality
          postalCode
          region
        }
        amenities {
          amenityId
          imageUrl
          name
        }
        badges {
          badgeId
          callToAction
          campaignId
          clickTrackingUrl
          description
          detailsImageUrl
          detailsImpressionTrackingUrls
          imageUrl
          impressionTrackingUrls
          internalName
          targetUrl
          title
        }
        brands {
          brandId
          brandingType
          imageUrl
          name
        }
        distance
        emergencyStatus {
          hasGas {
            nickname
            reportStatus
            updateDate
          }
          hasPower {
            nickname
            reportStatus
            updateDate
          }
          hasDiesel {
            nickname
            reportStatus
            updateDate
          }
        }
        enterprise
        fuels
        id
        latitude
        longitude
        name
        offers {
          discounts {
            grades
            highlight
            pwgbDiscount
            receiptDiscount
          }
          highlight
          id
          types
          use
        }
        payStatus {
          isPayAvailable
        }
        prices {
          cash {
            nickname
            postedTime
            price
            formattedPrice
          }
          credit {
            nickname
            postedTime
            price
            formattedPrice
          }
          fuelProduct
          longName
        }
        priceUnit
        ratingsCount
        reviews(limit: 1) {
          results {
            hasAgreed
            isReportable
            memberId
            overallRating
            profileImageUrl
            replies {
              authorName
              brand {
                brandId
                brandingType
                imageUrl
                name
              }
              createDate
              id
              text
            }
            review
            reviewDate
            reviewId
          }
        }
        starRating
      }
      offers {
        discounts {
          grades
          highlight
          pwgbDiscount
          receiptDiscount
        }
        highlight
        id
        types
        use
      }
      openStatus
      payStatus {
        isPayAvailable
      }
      phone
      prices {
        cash {
          nickname
          postedTime
          price
          formattedPrice
        }
        credit {
          nickname
          postedTime
          price
          formattedPrice
        }
        fuelProduct
        longName
      }
      priceUnit
      ratingsCount
      reviews(page: $reviewsPage) {
        count
        page
        results {
          agreeTotal
          hasAgreed
          isReportable
          memberId
          overallRating
          profileImageUrl
          replies {
            authorName
            brand {
              brandId
              brandingType
              imageUrl
              name
            }
            createDate
            id
            text
          }
          review
          reviewDate
          reviewId
        }
      }
      starRating
      topSpotters {
        avatar
        count
        name
        streak
      }
      isFuelmanSite
    }
  }
`;
