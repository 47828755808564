import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import BadgeAd from '../BadgeAd';
import NoClickPropagation from '../NoClickPropagation';
import styles from './BadgeAds.module.css';
import BadgeAdPropType from '../../prop-types/badge';
import useProfile from '../../../lib/hooks/useProfile';
import isPayAccountInGoodStanding from '../../../lib/utils/isPayAccountInGoodStanding';

const cx = classnames.bind(styles);

export default function BadgeAds({
  badges,
  hasPwGBCard,
  hasPwGBPlusCard,
  isPayAvailable,
  isFuelmanLocation,
  payAccountStatus,
}) {
  const finalBadges = [...(badges || [])];
  const { isPwGBUser, isPwGBPlusUser } = useProfile();

  if ((isPwGBPlusUser || hasPwGBPlusCard) && isFuelmanLocation) {
    finalBadges.push({
      badgeId: 'badge_fuelman_discount_available',
      callToAction: 'Got It',
      campaignId: '0',
      clickTrackingUrl: null,
      description: 'Select \'Fleetcard\' instead of \'Mastercard\' at the pump to save more at this station when using your Pay with GasBuddy+™ card. Auto Pay cardholders save 5¢/gal.',
      detailsImageUrl: null,
      detailsImpressionTrackingUrls: [],
      imageUrl: 'https://static.gasbuddy.com/web/pay/pwgb-plus-app-badge.png',
      impressionTrackingUrls: [],
      internalName: 'Fake Badge Ad To display additional discount at Fuelman locations',
      targetUrl: null,
      title: 'Card Savings Boost',
    });
  } else if ((isPwGBUser || hasPwGBCard)
    && isPayAvailable === false
    && isPayAccountInGoodStanding(payAccountStatus)
  ) {
    finalBadges.push({
      badgeId: 'badge_no_pay_available',
      callToAction: 'Got It',
      campaignId: '0',
      clickTrackingUrl: null,
      description: 'This station does not support you saving up to 25¢/gal with our Pay with GasBuddy card. However, there are thousands of stations that do! Look for stations without this badge and you’ll save on every gallon.',
      detailsImageUrl: null,
      detailsImpressionTrackingUrls: [],
      imageUrl: 'https://static.gasbuddy.com/web/pay/no-pwgb-support.png',
      impressionTrackingUrls: [],
      internalName: 'Fake Badge Ad To Flag Stations That Don\'t Support Pay with GasBuddy',
      targetUrl: null,
      title: 'This station does not accept Pay with GasBuddy',
    });
  }

  return finalBadges && (
    <div className={cx('badgeAds')}>
      {finalBadges.filter(Boolean).map(badge => (
        <NoClickPropagation key={badge.badgeId} className={cx('badgeAd')}>
          <BadgeAd {...badge} />
        </NoClickPropagation>
      ))}
    </div>
  );
}

BadgeAds.propTypes = {
  badges: PropTypes.arrayOf(BadgeAdPropType),
  hasPwGBCard: PropTypes.bool,
  hasPwGBPlusCard: PropTypes.bool,
  isPayAvailable: PropTypes.bool,
  isFuelmanLocation: PropTypes.bool,
  payAccountStatus: PropTypes.string,
};

BadgeAds.defaultProps = {
  badges: [],
  hasPwGBCard: false,
  hasPwGBPlusCard: false,
  isPayAvailable: undefined,
  isFuelmanLocation: undefined,
  payAccountStatus: undefined,
};
