import { useRouteMatch } from 'react-router-dom';
import useGeoIpInfo from '../../../lib/hooks/useGeoIpInfo';
import { canonicalizeString } from '../../../lib/utils';
import getLocale from '../../../lib/utils/getLocale';
import Schema from '../Schema';

const defaultWebpageMarkup = {
  name: 'Pay with GasBuddy - Apply Now for Our Discount Program Gas Card',
  description: 'When you Pay with GasBuddy+™ you can save up to 30¢/gallon on every fill-up at the pump. It\'s free to join and accepted at popular gas stations nationwide.',
};

const siteUrl = 'https://www.gasbuddy.com';

function getMainSchema({
  breadcrumbSchema,
  canonicalUrl,
  description,
  language,
  primaryImageObjDetails = {},
  productSchema,
  schema = undefined,
  title,
}) {
  const { product, helpers } = productSchema || {};
  const hasPrimaryImage = !!Object.keys(primaryImageObjDetails).length;

  return {
    '@context': 'https://schema.org/',
    '@graph': [
      {
        '@type': 'Organization',
        '@id': `${siteUrl}/#organization`,
        name: 'GasBuddy',
        url: siteUrl,
        sameAs: [],
        logo: {
          '@type': 'ImageObject',
          '@id': `${siteUrl}#logo`,
          inLanguage: 'en-US',
          url: 'https://static.gasbuddy.com/web/gb-app-2.svg',
          width: 92,
          height: 94,
          caption: 'GasBuddy',
        },
        image: {
          '@id': `${siteUrl}#logo`,
        },
      },
      {
        '@type': 'WebSite',
        '@id': `${siteUrl}#website`,
        url: siteUrl,
        name: 'GasBuddy',
        description: 'We believe no one should have to pay full price for gas.',
        publisher: {
          '@id': `${siteUrl}#organization`,
        },
        potentialAction: [
          {
            '@type': 'SearchAction',
            target: `${siteUrl}/home?search={search_term_string}`,
            'query-input': 'required name=search_term_string',
          },
        ],
        inLanguage: language,
      },
      ...(typeof product !== 'undefined' ? [{
        '@type': 'Product',
        name: product.name,
        image: {
          '@id': product.productImages.map(({ image }) => helpers.imageToSrc(image))?.[0],
        },
        description: product.description,
        brand: {
          '@type': 'Brand',
          name: 'GasBuddy',
        },
        offers: {
          '@type': 'Offer',
          url: product.offers[0].url,
          priceCurrency: product.offers[0].price.currency,
          price: product.offers[0].price.amount,
          availability: 'https://schema.org/InStock',
          seller: {
            '@type': 'Organization',
            name: 'GasBuddy',
          },
        },
      }] : []),
      ...(hasPrimaryImage ? [{
        '@type': 'ImageObject',
        '@id': `${siteUrl}/pay#primaryimageofpage`,
        author: 'GasBuddy',
        ...primaryImageObjDetails,
      }] : []),
      {
        '@type': 'WebPage',
        '@id': `${canonicalUrl}#webpage`,
        url: canonicalUrl || siteUrl,
        name: title || defaultWebpageMarkup.name,
        isPartOf: {
          '@id': `${siteUrl}#website`,
        },
        about: {
          '@id': `${siteUrl}#organization`,
        },
        description: description || defaultWebpageMarkup.description,
        inLanguage: language,
        ...(hasPrimaryImage && {
          primaryImageOfPage: {
            '@id': `${canonicalUrl}#primaryimageofpage`,
          },
        }),
        ...(breadcrumbSchema ? [{ ...breadcrumbSchema }] : []),
      },
      ...(schema ? [{ ...schema }] : []),
    ],
  };
}

export const MainSchema = (mainSchemaProps) => {
  const { url } = useRouteMatch();
  const cleanPath = url.endsWith('/') ? url.substring(0, url.lastIndexOf('/')) : url;
  const canonicalPath = canonicalizeString(cleanPath, true);
  const props = {
    ...mainSchemaProps,
    canonicalUrl: `${siteUrl}${canonicalPath}`,
    path: url,
  };
  const { country } = useGeoIpInfo();
  const language = getLocale(country);
  const schema = getMainSchema({ ...props, language });

  return (
    <Schema value={schema} />
  );
};

export default MainSchema;
