import { faGripVertical } from '@fortawesome/free-solid-svg-icons/faGripVertical';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Belt, FlexGrid, Panel } from '@gasbuddy/react-components';
import { useWindowSize } from '@gasbuddy/react-hooks/build/hooks/useWindowSize';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Fragment, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import useFeatureGatedSlashPricing from '../../../lib/hooks/useFeaturedGatedSlashPricing';
import useFeatureGatedDealAlerts from '../../../lib/hooks/useFeatureGatedDealAlerts';
import useFormattedStationPrices from '../../../lib/hooks/useFormattedStationPrices';
import useProfile from '../../../lib/hooks/useProfile';
import useStationPayAvailability from '../../../lib/hooks/useStationPayAvailability';
import { noop } from '../../../lib/utils';
import { isValidFormattedPrice } from '../../../lib/utils/prices';
import { KEYNAMES } from '../../constants';
import StationContext from '../../context/station';
import FuelTypePropType from '../../prop-types/fuelType';
import StationPropType from '../../prop-types/station';
import DealAlertHighlight from '../DealAlertHighlight';
import FavoriteStationIcon from '../FavoriteStationIcon';
import StationDisplay from '../StationDisplay';
import StationDisplayPrice from '../StationDisplayPrice';
import StationOfferPrice from '../StationOfferPrice';
import VerboseStationInfo from '../VerboseStationInfo';
import styles from './GenericStationListItem.module.css';

const cx = classnames.bind(styles);

export default function GenericStationListItem({
  fuelType,
  hasPwGBCard,
  hasPwGBPlusCard,
  hideDeals,
  includeCash,
  isFullTabletWidth,
  onPriceClicked,
  payAccountStatus,
  shouldVisitStation,
  showBadges,
  showFavoriting,
  station,
  verbose,
}) {
  const history = useHistory();
  const showOffers = useFeatureGatedSlashPricing();
  const showDealAlerts = useFeatureGatedDealAlerts();
  const { mobile: isMobileDisplay, tablet: isTabletDisplay } = useWindowSize();
  const { isPayUser } = useProfile();

  const visitStation = useCallback((e) => {
    // Let links handle themselves
    const linkWasClicked = e.target.tagName === 'A';

    if (shouldVisitStation
      && !linkWasClicked
      && station.id
      && (!e.key || [KEYNAMES.ENTER, KEYNAMES.SPACE].includes(e.key))
    ) {
      history.push(`/station/${station.id}`);
    }
  }, [history, shouldVisitStation, station.id]);

  const {
    amenities,
    id: stationId,
    offers,
    prices: stationPrices,
    priceUnit,
  } = station || {};

  const priceContainerProps = showFavoriting && {
    onClick: onPriceClicked,
  };

  const reviews = station.reviews?.results;
  const {
    discountedPrice,
    cashPriceReport,
    creditPriceReport,
    hasActiveCashPrice,
  } = useFormattedStationPrices(priceUnit, fuelType, offers, stationPrices);

  const showCashPriceReport = includeCash && hasActiveCashPrice;
  const priceReport = showCashPriceReport ? cashPriceReport : creditPriceReport;

  const isPayAvailable = useStationPayAvailability(station);
  const shouldShowOfferPrice = !showFavoriting && showOffers && isPayAvailable && discountedPrice && isPayUser;
  const dealAlertOffer = offers?.find(offer => offer.types.includes('getupside'));
  const seesDeal = !hideDeals && showDealAlerts && isPayAvailable && !!dealAlertOffer;
  const visibleDealArrow = seesDeal && shouldShowOfferPrice;
  const beltStylesOnTablet = isTabletDisplay && !isFullTabletWidth;

  // always start with our basic display price
  let components = [
    <div
      className={cx('priceCard', { card: !visibleDealArrow, clickable: !showFavoriting })}
      {...priceContainerProps}
    >
      <StationDisplayPrice
        asCard={!isFullTabletWidth}
        bordered={isValidFormattedPrice(priceReport?.formattedPrice)}
        formattedPrice={priceReport?.formattedPrice}
        isCash={hasActiveCashPrice}
        postedTime={priceReport?.postedTime}
        reportedBy={priceReport?.reportedBy}
      />
    </div>,
  ];

  // front append the offer price if one is available
  if (shouldShowOfferPrice) {
    components.unshift(
      <div className={cx('priceCard', { card: !visibleDealArrow })}>
        <StationOfferPrice
          asCard={!isFullTabletWidth}
          largeStyles
          payPrice={discountedPrice}
          regularPriceReport={creditPriceReport}
          showPriceTab={hasActiveCashPrice}
          smallMobilePrice
        />
      </div>,
    );
  }

  // apply panels for mobile view Belt, but do not apply the belt item container styles to the Belt cards
  // if the deal alert arrow is present (because it handles the necessary styling in the next step)
  if (isMobileDisplay || beltStylesOnTablet) {
    components = components.map(component => (
      <Panel
        raised
        padded={false}
        color="white"
        className={cx('pricePanel', { beltPriceContainer: !visibleDealArrow })}
      >
        {component}
      </Panel>
    ));
  }

  // Apply the deal arrow spacing to all components in the list so the styles are consistent
  if (visibleDealArrow) {
    components = components.map((component, index) => (
      <div
        key={components.length === 1 || index === 1 ? 'standard_price_display' : 'pay_price_display'}
        className={cx(
          'withArrowCard',
          { beltPriceContainer: isMobileDisplay || beltStylesOnTablet },
        )}
      >
        <div className={cx('arrowSpacing', { arrow: index === 0 })} />
        {component}
      </div>
    ));
  } else {
    // Apply the key for the rendered mapping of the components
    components = components.map((component, index) => (
      <Fragment key={components.length === 1 || index === 1 ? 'standard_price_display' : 'pay_price_display'}>
        {component}
      </Fragment>
    ));
  }

  const stationDetailsJsx = (
    <Fragment>
      {seesDeal && (
        <DealAlertHighlight
          offer={dealAlertOffer}
        />
      )}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div className={cx('stationListItem')} onClick={visitStation} onKeyDown={visitStation}>
        <StationDisplay
          showBrands
          showBadges={showBadges && !showFavoriting}
          station={station}
          hasPwGBCard={hasPwGBCard}
          hasPwGBPlusCard={hasPwGBPlusCard}
          payAccountStatus={payAccountStatus}
        />
        <div className={cx('badgeContainer')}>
          {showFavoriting && <FavoriteStationIcon stationId={stationId} size="2x" />}
        </div>

        <Belt
          activeOnTablet={!isFullTabletWidth}
          className={cx('beltContainer', { withArrow: visibleDealArrow })}
          name={`Station${stationId}Listing_PriceBelt`}
          tabIndex={-1}
        >
          {components}
        </Belt>
      </div>
      {verbose && <VerboseStationInfo amenities={amenities} reviews={reviews} stationId={stationId} />}
    </Fragment>
  );

  return (
    <StationContext.Provider value={stationId}>
      <Panel
        bordered
        className={cx('station')}
        color="white"
        padded={false}
        rounded
        id={stationId}
      >
        {showFavoriting
          ? (
            <FlexGrid container>
              <FlexGrid.Column mobile={1} className={cx('dragIconContainer')}>
                <div className={cx('verticalEllipsisContainer')}>
                  <FontAwesomeIcon icon={faGripVertical} className={cx('verticalEllipsis')} size="2x" />
                </div>
              </FlexGrid.Column>
              <FlexGrid.Column mobile={11} className={cx('stationDetails', 'clickable')}>
                {stationDetailsJsx}
              </FlexGrid.Column>
            </FlexGrid>
          )
          : stationDetailsJsx
        }
      </Panel>
    </StationContext.Provider>
  );
}

GenericStationListItem.propTypes = {
  fuelType: FuelTypePropType,
  hasPwGBCard: PropTypes.bool,
  hasPwGBPlusCard: PropTypes.bool,
  /** A manual toggle to show deal alerts or not (ex: Favorite Stations view mode) */
  hideDeals: PropTypes.bool,
  includeCash: PropTypes.bool,
  isFullTabletWidth: PropTypes.bool,
  onPriceClicked: PropTypes.func,
  payAccountStatus: PropTypes.string,
  /** whether clicking the list item takes the user to its details page or not */
  shouldVisitStation: PropTypes.bool,
  showBadges: PropTypes.bool,
  showFavoriting: PropTypes.bool,
  station: StationPropType,
  /** whether to show amenities and reviews pop-down */
  verbose: PropTypes.bool,
};

GenericStationListItem.defaultProps = {
  fuelType: 1,
  hasPwGBCard: false,
  hasPwGBPlusCard: false,
  hideDeals: false,
  includeCash: true,
  isFullTabletWidth: false,
  onPriceClicked: noop,
  payAccountStatus: undefined,
  shouldVisitStation: true,
  showBadges: true,
  showFavoriting: false,
  station: {},
  verbose: false,
};
